<template>
  <div class="parent-sidebar">
    <!-- nav class="left-menu" id="sidebarMainMenu" -->
    <b-collapse
      id="sidebarMainMenu"
      class="left-menu"
      :class="{ show: subMenuOpened }"
      ref="sideBarEl"
    >
      <div class="side-bar-sub">
        <header>
          <template v-for="menu in mainMenu">
            <!--
            <router-link
              :to="menu.path"
              :key="menu.path"
              :class="{ active: menu.path === currentPath }"
              @click.native="reqCloseMenus(true)"
              v-if="menu.path === '/live' && account.is_agent"
            >
              <i :class="menu.icon"></i>
              <template v-if="menu.path === '/live'">
                <audio
                  controls
                  ref="soundNotification"
                  id="soundNotification"
                  :muted="$live.volume <= 0"
                >
                  <source
                    src="/sounds/notification.ogg"
                    type="audio/ogg; codecs=vorbis"
                  />
                  <source src="/sounds/notification.mp3" type="audio/mpeg" />
                </audio>
                <div
                  :class="{
                    'live-pushs': true,
                    'live-online': $live.online,
                    'live-notifications': $live.hasNotifications,
                  }"
                ></div>
              </template>
            </router-link>
            -->
            <template v-if="menu.id === 'whatsapp2'">
              <!-- <p :key="menu.id">
                {{ menu.id }}
                {{ !$live.isMeta }}
                {{groupPermission(menu.id)}}
              </p> -->
              <b-button
                :id="`sidebarId_${menu.id}`"
                :key="menu.id"
                :class="{ active: menu.id === currentPath }"
                v-b-toggle="`sidebarSubMenu${menu.id}`"
                v-if="
                  account.whitelabel_id === null ||
                  (!$live.isMeta && groupPermission(menu.id))
                "
              >
                <i :class="menu.icon"></i>
              </b-button>
            </template>
            <template v-else-if="menu.id === 'whatsappjava'">
              <!-- <p :key="menu.id">
                {{ menu.id }}
                {{ $live.isMeta }}
                {{groupPermission(menu.id)}}
              </p> -->
              <b-button
                :id="`sidebarId_${menu.id}`"
                :key="menu.id"
                :class="{ active: menu.id === currentPath }"
                v-b-toggle="`sidebarSubMenu${menu.id}`"
                v-if="
                  account.whitelabel_id === null ||
                  ($live.isMeta && groupPermission(menu.id))
                "
              >
                <i :class="menu.icon"></i>
              </b-button>
            </template>
            <template v-else-if="menu.path">
              <router-link
                :to="menu.path"
                :key="menu.path"
                :class="{ active: menu.path === currentPath }"
                @click.native="reqCloseMenus(true)"
                v-if="menu.path !== '/live' && groupPermission(menu.id)"
              >
                <i :class="menu.icon"></i>
              </router-link>
            </template>
            <template v-else>
              <b-button
                :id="`sidebarId_${menu.id}`"
                :key="menu.id"
                :class="{ active: menu.id === currentPath }"
                v-b-toggle="`sidebarSubMenu${menu.id}`"
                v-if="groupPermission(menu.id)"
              >
                <i :class="menu.icon"></i>
                <template v-if="menu.id === 'live'">
                  <audio
                    controls
                    ref="soundNotification"
                    id="soundNotification"
                    :muted="$live.volume <= 0"
                  >
                    <source
                      src="/sounds/notification.ogg"
                      type="audio/ogg; codecs=vorbis"
                    />
                    <source src="/sounds/notification.mp3" type="audio/mpeg" />
                  </audio>
                  <div
                    :class="{
                      'live-pushs': true,
                      'live-notifications': $live.hasNotifications,
                      'bg-success': $live.status === 'AVAILABLE',
                      'bg-warning': $live.status === 'UNAVAILABLE',
                      'bg-info': $live.status === 'BREAK',
                      'bg-danger': $live.status === 'OFFLINE',
                    }"
                    v-if="account.is_agent"
                  ></div>
                </template>
              </b-button>
            </template>
          </template>
          <router-link
            to="/flows"
            @click.native="reqCloseMenus(true)"
            :class="{
              active:
                currentPath &&
                (currentPath === '/flows' ||
                  currentPath.indexOf('/flows/') === 0),
            }"
            v-if="account.whitelabel_id === null"
          >
            <i class="fas fa-sitemap fa-fw"></i>
          </router-link>
          <b-button
            v-if="account.whitelabel_id === null"
            class="menu-config"
            v-b-toggle.sidebarSubMenuAdmin
          >
            <i class="fas fa-briefcase fa-fw"></i>
          </b-button>
        </header>

        <footer>
          <b-button
            class="menu-config"
            id="menu-volume"
            ref="menuVolume"
            @click="toggleLiveVolume()"
            v-if="account.is_agent"
          >
            <i class="fas fa-volume-mute" v-if="$live.volume == 0"></i>
            <i class="fas fa-volume-up" v-else-if="$live.volume > 0.9"></i>
            <i class="fas fa-volume-down" v-else></i>
          </b-button>

          <b-button
            class="menu-config"
            v-b-toggle.sidebarSubMenuConfig
            v-if="groupPermission('settings')"
          >
            <i class="fas fa-cog fa-fw"></i>
          </b-button>

          <b-button
            class="menu-config"
            v-b-toggle.sidebarSubMenuHelp
            v-if="groupPermission('docs')"
          >
            <i class="fas fa-life-ring fa-fw"></i>
          </b-button>
          <!-- <b-button class="menu-config ra-button" v-b-toggle.sidebarSubMenuRa>
            <span></span>
          </b-button> -->
        </footer>

        <button
          :class="{
            'scroll-to-top': true,
            'scroll-visible': scrollAtBottom,
          }"
          @click="scrollTo(true)"
        >
          <i class="fas fa-ellipsis-h"></i>
        </button>
        <button
          :class="{
            'scroll-to-bottom': true,
            'scroll-visible': scrollAtTop,
          }"
          @click="scrollTo(false)"
        >
          <i class="fas fa-ellipsis-h"></i>
        </button>
      </div>
    </b-collapse>
    <!-- /nav -->

    <template v-for="menu in mainMenu">
      <b-collapse
        :id="`sidebarSubMenu${menu.id}`"
        accordion="sidebarSubMenu"
        class="text-nowrap"
        :key="menu.id"
      >
        <aside class="list-group list-group-flush">
          <h3>
            {{ menu.title }}
            <span class="badge badge-warning" v-if="menu.badge">{{
              menu.badge
            }}</span>
          </h3>
          <template v-for="link in menu.subMenu">
            <div
              class="list-group-item list-group-item-action list-disabled"
              :key="link.path"
              v-if="link.disabled"
            >
              <i :class="`mr-2 ${link.icon}`"></i>
              {{ link.description }}
              <span class="badge badge-warning" v-if="link.badge">{{
                link.badge
              }}</span>
            </div>
            <router-link
              @click.native="reqCloseMenus(true)"
              class="list-group-item list-group-item-action"
              :key="link.path"
              :to="link.path"
              v-else-if="
                link.path === '/live' ? account.is_agent : permission(link.path)
              "
            >
              <i :class="`mr-2 ${link.icon}`"></i>
              {{ link.description }}
              <template v-if="menu.id === 'live' && link.path === '/live'">
                <span class="badge badge-warning" v-if="$live.queue > 0">
                  {{ $live.queue > 999 ? '+999' : $live.queue }}
                </span>
                <span class="badge badge-success" v-if="$live.unread > 0">
                  {{ $live.unread > 999 ? '+999' : $live.unread }}
                </span>
              </template>
              <span class="badge badge-warning" v-else-if="link.badge">
                {{ link.badge }}
              </span>
            </router-link>
          </template>
          <a
            :href="`/live/simulator#${$live.accountId}`"
            :target="`_yup-chat-simulator:${$live.accountId}`"
            class="list-group-item list-group-item-action"
            v-if="menu.id === 'live' && $live.accountId"
          >
            <i class="fas fa-external-link-alt fa-fw"></i>
            Simular widget
          </a>
          <!-- <template v-if="menu.id === 'live'">
            <pre>{{menu}}</pre>
          </template> -->
          <router-link
            @click.native="reqCloseMenus(true)"
            class="list-group-item list-group-item-action"
            to="/billing/upgrade"
            v-if="menu.id === 'main' && account.type == 'trial'"
          >
            <i class="mr-2 fas fa-level-up-alt"></i> Upgrade
          </router-link>
        </aside>
      </b-collapse>
    </template>

    <b-collapse
      id="sidebarSubMenuAdmin"
      accordion="sidebarSubMenu"
      class="text-nowrap"
      v-if="groupPermission('manager')"
    >
      <h3>{{ $t('generic-str.menu.management') }}</h3>
      <!-- Gestão -->

      <template v-for="menu in adminMenu">
        <aside
          class="list-group list-group-flush"
          :key="menu.title"
          v-if="!menu.management || account.whitelabel_id == null"
        >
          <h4>{{ menu.title }}</h4>
          <router-link
            @click.native="reqCloseMenus(true)"
            class="list-group-item list-group-item-action"
            v-for="link in menu.links"
            :key="link.description"
            :to="link.path"
          >
            <i :class="`mr-2 fas fa-${link.icon} fa-fw`"></i>
            {{ link.description }}
          </router-link>
          <button
            class="list-group-item list-group-item-action"
            @click="rebranding()"
            v-if="menu.title === 'Developer'"
          >
            <i :class="`mr-2 fas fa-tools fa-fw`"></i>
            {{ $rebranding.active ? 'Desativar' : 'Ativar' }} rebranding
          </button>
        </aside>
      </template>
    </b-collapse>

    <b-collapse
      id="sidebarSubMenuHelp"
      accordion="sidebarSubMenu"
      class="text-nowrap"
      v-if="groupPermission('docs')"
    >
      <h3>{{ $t('generic-str.menu.docs-n-supp') }}</h3>
      <!-- Docs e Suporte -->

      <template v-for="menu in supportMenu">
        <aside class="list-group list-group-flush" :key="menu.title">
          <span v-for="link in menu.links" :key="link.path">
            <a
              v-if="link.external"
              class="list-group-item list-group-item-action"
              :href="link.path"
              target="_blank"
            >
              <i :class="`mr-2 fas fa-${link.icon} fa-fw`"></i>
              {{ link.description }}
              <i class="mr-2 fas fa-external-link-alt fa-fw"></i>
            </a>
            <router-link
              v-else
              @click.native="reqCloseMenus(true)"
              class="list-group-item list-group-item-action"
              :to="link.path"
            >
              <i :class="`mr-2 fas fa-${link.icon} fa-fw`"></i>
              {{ link.description }}
            </router-link>
          </span>
        </aside>
      </template>
    </b-collapse>

    <b-collapse
      id="sidebarSubMenuRa"
      accordion="sidebarSubMenu"
      class="text-nowrap"
    >
      <h3>Reclame aqui</h3>

      <template v-for="menu in raMenu">
        <aside class="list-group list-group-flush" :key="menu.title">
          <span v-for="link in menu.links" :key="link.path">
            <a
              v-if="link.external"
              class="list-group-item list-group-item-action"
              :href="link.path"
              target="_blank"
            >
              <i :class="`mr-2 fas fa-${link.icon} fa-fw`"></i>
              {{ link.description }}
              <i class="mr-2 fas fa-external-link-alt fa-fw"></i>
            </a>
            <router-link
              v-else
              @click.native="reqCloseMenus(true)"
              class="list-group-item list-group-item-action"
              :to="link.path"
            >
              <i :class="`mr-2 fas fa-${link.icon} fa-fw`"></i>
              {{ link.description }}
            </router-link>
          </span>
        </aside>
      </template>
    </b-collapse>

    <b-collapse
      id="sidebarSubMenuConfig"
      accordion="sidebarSubMenu"
      class="text-nowrap"
    >
      <h3>{{ this.$t('generic-str.menu.config') }}</h3>
      <!-- Configurações -->

      <template v-for="menu in submenu">
        <aside
          class="list-group list-group-flush"
          :key="menu.title"
          v-if="!menu.management || account.whitelabel_id === null"
        >
          <h4>{{ menu.title }}</h4>
          <template v-for="link in menu.links">
            <router-link
              @click.native="reqCloseMenus(true)"
              class="list-group-item list-group-item-action"
              :key="link.path"
              :to="link.path"
              v-if="
                (!link.management || account.whitelabel_id === null) &&
                permission(link.path)
              "
            >
              <i :class="`mr-2 fas fa-${link.icon} fa-fw`"></i>
              {{ link.description }}
            </router-link>
          </template>
        </aside>
      </template>
    </b-collapse>
    <div data-target="menu-volume" ref="targetMenuVolume">
      <div class="menu-volume-sub">
        <input
          type="range"
          step="0.02"
          min="0"
          max="1.0"
          class="target-input"
          :data-volume="$live.volume"
          v-model="$live.volume"
          @change="changeLiveVolume"
          v-if="$live"
        />
        <div class="target-icon" @click="toggleLiveVolume()">
          <i class="fas fa-volume-up" v-if="$live.volume > 0.9"></i>
          <i class="fas fa-volume-mute" v-else-if="$live.volume == 0"></i>
          <i class="fas fa-volume-down" v-else></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RCSService from '@/services/rcs.service';
import WhatsAppService from '@/services/whatsapp.service';
import Permissions from '@/router/permissions';

export default {
  name: 'Sidebar',
  data() {
    return {
      hasNotification: false,
      audioEnabled: false,
      audioIssue: false,

      currentPath: null,
      client: {},
      clientWhats: {},

      scrollAtTop: true,
      scrollAtBottom: false,

      closingMenu: false,
      subMenuOpened: false,
      closeMenusTimeout: 0,

      timeoutSoundRunning: false,
      timeoutSound: -1,

      menu: ['billing', 'usage'].includes(this.$route.path.split('/')[1])
        ? ''
        : this.$route.path.split('/')[1],

      // <i class="fas fa-sms"></i>

      mainMenu: [
        {
          id: 'main',
          title: 'Principal',
          icon: 'fas fa-th-large fa-fw',
          subMenu: [
            {
              path: '/',
              description: 'Dashboard',
              icon: 'fas fa-th-large fa-fw',
            },
            {
              path: '/billing',
              description: this.$t('app.billing'), // Faturamento
              icon: 'fas fa-building fa-fw',
            },

            // {
            //   path: '/settings',
            //   description: 'Configurações',
            //   icon: 'fas fa-id-badge fa-fw',
            // },

            // {
            //   path: '/webhooks',
            //   description: 'Webhooks',
            //   icon: 'fas fa-briefcase fa-fw',
            // },

            {
              path: '/users',
              description: this.$t('menu.principal.users'), // Usuários
              icon: 'fas fa-user-friends fa-fw',
            },
            {
              path: '/invoices',
              description: this.$t('menu.principal.invoices'), // Faturas
              icon: 'fas fa-money-bill-wave fa-fw',
            },
          ],
        },
        {
          id: 'sms',
          title: 'SMS',
          icon: 'fas fa-sms fa-fw',
          subMenu: [
            {
              path: '/sms/dashboard',
              icon: 'fas fa-tachometer-alt fa-fw',
              description: 'Dashboard',
            },
            {
              path: '/sms/insights',
              icon: 'fas fa-info fa-fw',
              description: this.$t('generic-str.menu.infos'), // Informações
            },
            {
              path: '/sms/send',
              icon: 'fas fa-paper-plane fa-fw',
              description: this.$t('generic-str.menu.send-msg'), // Enviar Mensagem
            },
            {
              path: '/sms/short-codes',
              icon: 'fas fa-hashtag fa-fw',
              description: 'Short Codes',
            },
            {
              path: '/sms/templates',
              icon: 'fas fa-archive fa-fw',
              description: 'Templates',
            },
            {
              path: '/sms/campaigns',
              icon: 'fas fa-bullhorn fa-fw',
              description: this.$t('generic-str.menu.campaign'), // Campanhas
            },
            {
              path: '/sms/history',
              icon: 'fas fa-history fa-fw',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
            {
              path: '/sms/denylist',
              icon: 'fas fa-ban fa-fw',
              description: 'Deny list',
              badge: 'Beta',
            },
            {
              path: '/sms/optouts',
              icon: 'fas fa-key fa-fw',
              description: 'Opt-Outs',
              badge: 'Beta',
            },
            // {
            //   visible: this.$store.state.stage <= 0,
            //   path: '/sms/queue',
            //   icon: 'list',
            //   description: 'Fila',
            // },
            {
              visible: this.$store.state.stage <= 0,
              path: '/sms/usage',
              icon: 'fas fa-chart-bar fa-fw',
              description: this.$t('sms.usage-report.header'), // Relatório de Uso
            },
            // {
            //   visible: this.$store.state.stage <= 0,
            //   path: 'apps.mail.html',
            //   link: true,
            //   icon: 'cog',
            //   description: 'Configurações',
            // },
            {
              path: '/sms/smpp',
              icon: 'fas fa-users fa-fw',
              description: this.$t('generic-str.menu.smpp-users'), // Usuários SMPP
            },
          ],
        },
        {
          id: 'whatsapp2',
          title: 'WhatsApp',
          badge: 'Beta',
          icon: 'fab fa-whatsapp fa-fw',
          subMenu: [
            {
              path: '/whatsapp',
              icon: 'fas fa-tachometer-alt fa-fw',
              description: 'Dashboard',
            },
            {
              path: '/whatsapp/insights',
              icon: 'fas fa-info fa-fw',
              description: this.$t('generic-str.menu.infos'), // Informações
            },
            {
              path: '/whatsapp/send',
              icon: 'fas fa-paper-plane fa-fw',
              description: this.$t('generic-str.menu.send-msg'), // Enviar Mensagem
            },
            {
              path: '/whatsapp/templates',
              icon: 'fas fa-archive fa-fw',
              description: 'Templates',
            },
            {
              path: '/whatsapp/campaigns',
              icon: 'fas fa-bullhorn fa-fw',
              description: this.$t('generic-str.menu.campaign'), // Campanhas
            },
            {
              path: '/whatsapp/history',
              icon: 'fas fa-history fa-fw',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
            {
              path: '/whatsapp/denylist',
              icon: 'fas fa-ban fa-fw',
              description: 'Deny list',
            },
            {
              path: '/whatsapp/optouts',
              icon: 'fas fa-key fa-fw',
              description: 'Opt-Outs',
            },
          ],
        },
        {
          id: 'whatsappjava',
          title: 'WhatsApp',
          badge: 'Beta',
          icon: 'fab fa-whatsapp fa-fw',
          subMenu: [
            {
              path: '/whatsapp/jv/send',
              icon: 'fas fa-paper-plane fa-fw',
              description: this.$t('generic-str.menu.send-msg'), // Enviar Mensagem
            },
            {
              path: '/whatsapp/jv/history',
              icon: 'fas fa-history fa-fw',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
            {
              path: '/whatsapp/jv/templates',
              icon: 'fas fa-archive fa-fw',
              description: 'Templates',
            },
            {
              path: '/whatsapp/jv/denylist',
              icon: 'fas fa-ban fa-fw',
              description: 'Deny list',
            },
          ],
        },
        {
          id: 'rcs',
          title: 'RCS Google',
          icon: 'fab fa-google fa-fw',
          badge: 'Beta',
          subMenu: [
            {
              path: '/rcs',
              icon: 'fas fa-tachometer-alt fa-fw',
              description: 'Dashboard',
            },
            {
              path: '/rcs/insights',
              icon: 'fas fa-info fa-fw',
              description: this.$t('generic-str.menu.infos'), // Informações
            },
            {
              path: '/rcs/send',
              icon: 'fas fa-paper-plane fa-fw',
              description: this.$t('generic-str.menu.send-msg'), // Enviar Mensagem
            },
            {
              path: '/rcs/campaigns',
              icon: 'fas fa-bullhorn fa-fw',
              description: this.$t('generic-str.menu.campaign'), // Campanhas
            },
            {
              path: '/rcs/history',
              icon: 'fas fa-history fa-fw',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
            {
              path: '/rcs/denylist',
              icon: 'fas fa-ban fa-fw',
              description: 'Deny list',
            },
            {
              path: '/rcs/optouts',
              icon: 'fas fa-key fa-fw',
              description: 'Opt-Outs',
            },
          ],
        },
        {
          id: 'mail',
          title: 'E-mail',
          icon: 'fas fa-envelope-open fa-fw',
          badge: 'Beta',
          subMenu: [
            {
              path: '/mail/dashboard',
              icon: 'fas fa-tachometer-alt fa-fw',
              description: 'Dashboard',
            },
            {
              path: '/mail/insights',
              icon: 'fas fa-info fa-fw',
              description: this.$t('generic-str.menu.infos'), // Informações
            },
            {
              path: '/mail/send',
              icon: 'fas fa-paper-plane fa-fw',
              description: this.$t('generic-str.menu.send-email'), // Enviar email
            },
            {
              path: '/mail/templates',
              icon: 'fas fa-archive fa-fw',
              description: 'Templates',
            },
            {
              path: '/mail/campaigns',
              icon: 'fas fa-bullhorn fa-fw',
              description: this.$t('generic-str.menu.campaign'), // Campanhas
            },
            {
              path: '/mail/history',
              icon: 'fas fa-history fa-fw',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
            {
              path: '/mail/domains',
              icon: 'fas fa-globe-europe fa-fw',
              description: this.$tc('generic-str.menu.domains', 2), // Domínios
            },
            {
              path: '/mail/denylist',
              icon: 'fas fa-ban fa-fw',
              description: 'Deny list',
            },
            {
              path: '/mail/optouts',
              icon: 'fas fa-key fa-fw',
              description: 'Opt-Outs',
            },
          ],
        },
        // {
        //   id: 'channels',
        //   title: 'Canais',
        //   path: '/channels',
        //   icon: 'fas fa-network-wired fa-fw',
        // },
        {
          id: 'contacts',
          title: this.$tc('sms.send-msg.tabs.contact.title', 2), // Contatos
          icon: 'fas fa-address-book fa-fw',
          subMenu: [
            {
              path: '/contacts',
              description: this.$t('generic-str.menu.list'), // Lista
              icon: 'fas fa-user',
            },
            {
              path: '/contacts/groups',
              description: this.$t('generic-str.menu.groups'), // Grupos
              icon: 'fas fa-users',
            },
            {
              path: '/contacts/import',
              description: this.$t('generic-str.import'), // Importar
              icon: 'fas fa-upload',
            },
          ],
        },
        // {
        //   id: 'campaigns',
        //   title: 'Campanhas',
        //   icon: 'fas fa-bullhorn fa-fw',
        //   subMenu: [
        //     {
        //       path: '/sms/campaigns',
        //       icon: 'fas fa-sms fa-fw',
        //       description: 'SMS',
        //     },
        //     {
        //       path: '/mail/campaigns',
        //       icon: 'fas fa-envelope-open fa-fw',
        //       description: 'Email',
        //     },
        //     {
        //       path: '/whatsapp/campaigns',
        //       icon: 'fab fa-whatsapp fa-fw',
        //       description: 'WhatsApp',
        //       badge: 'Beta',
        //     },
        //     {
        //       path: '/rcs/campaigns',
        //       icon: 'fas fa-comment-alt fa-fw',
        //       description: 'RCS Google',
        //       badge: 'Beta',
        //     },
        //     {
        //       path: '/telegram/campaigns',
        //       icon: 'fab fa-telegram-plane fa-fw',
        //       description: 'Telegram',
        //       badge: 'Em breve',
        //       disabled: true,
        //     },
        //     {
        //       path: '/voice/campaigns',
        //       icon: 'fas fa-microphone-alt fa-fw',
        //       description: 'Voz',
        //       badge: 'Em breve',
        //       disabled: true,
        //     },
        //   ],
        // },
        {
          id: 'lookup',
          title: 'Lookup',
          icon: 'fas fa-search',
          subMenu: [
            {
              path: '/lookup',
              icon: 'fas fa-id-badge fa-fw',
              description: this.$t('generic-str.menu.verify-number'), // Verificar Número
            },
            {
              path: '/lookup/list',
              icon: 'fas fa-briefcase fa-fw',
              description: this.$t('generic-str.menu.verified-number'), // Números Verificados
            },
          ],
        },
        {
          id: 'live',
          // title: 'Omni Business',
          title: 'Chat',
          icon: 'fas fa-headset fa-fw',
          subMenu: [
            {
              path: '/live/dashboard',
              icon: 'fas fa-tachometer-alt fa-fw',
              description: 'Dashboard',
            },
            {
              path: '/live',
              icon: 'fas fa-headset fa-fw',
              description: 'Atendimento',
            },
            {
              path: '/live/manage',
              icon: 'fas fa-user-secret fa-fw',
              description: 'Agentes',
            },
            {
              path: '/live/history',
              icon: 'fas fa-tachometer-alt fa-fw',
              description: this.$tc('app.history', 2),
            },
            // {
            //   path: '/webchat/configs',
            //   icon: 'fas fa-gears fa-fw',
            //   description: 'Configurar',
            // },
            {
              path: '/live/configs/holidays',
              icon: 'fas fa-calendar-check fa-fw',
              description: 'Feriados',
            },
            {
              path: '/live/configs/opening-hours',
              icon: 'fas fa-clock fa-fw',
              description: 'Expediente',
            },
            {
              path: '/live/configs/responses',
              icon: 'fas fa-comment-dots fa-fw',
              description: 'Respostas',
            },
            {
              path: '/live/configs/tabulations',
              icon: 'fas fa-list fa-fw',
              description: 'Tabulação',
            },
            {
              path: '/channels',
              icon: 'fas fa-network-wired fa-fw',
              description: 'Canais',
            },
            {
              path: '/live/configs/system',
              icon: 'fas fa-cogs fa-fw',
              description: 'Sistema',
            },
            {
              path: '/live/configs/widget',
              icon: 'fas fa-globe fa-fw',
              description: 'Widget',
            },
          ],
        },
        // {
        //   path: '/live',
        //   icon: 'fas fa-headset fa-fw',
        // },
        // {
        //   path: '/flows',
        //   icon: 'fas fa-sitemap fa-fw',
        // },
        // {
        //   path: '/users',
        //   title: 'Usuários',
        //   icon: 'fas fa-user-friends fa-fw',
        // },
        // { path: '/support', icon: 'fas fa-chart-bar fa-fw' },
        // { path: '/live/automatic', icon: 'fas fa-portrait fa-fw' },
        // { path: '/live/features', icon: 'far fa-clock' },
        // { path: '/account/departments', icon: 'far fa-clock' },
      ],

      supportMenu: [
        {
          links: [
            {
              path: '/support',
              icon: 'fas fa-ticket-alt fa-fw',
              description: this.$t('generic-str.menu.calls'), // Chamados
            },
            {
              path: 'https://doc.yup.chat/',
              icon: 'fas fa-code fa-fw',
              description: this.$t('generic-str.menu.api-doc'), // Documentação da API
              external: true,
            },
            {
              path: 'https://yup.chat/faq/',
              icon: 'fas fa-question-circle fa-fw',
              description: this.$t('generic-str.menu.knowledge-center'), // Central de Conhecimento
              external: true,
            },
            {
              path: 'https://yup.chat/politica-de-privacidade/',
              icon: 'fas fa-user-secret fa-fw',
              description: this.$t('generic-str.menu.privacy-policy'), // Política de privacidade
              external: true,
            },
          ],
        },
      ],

      raMenu: [
        {
          title: this.$t('generic-str.menu.integrations'), // Integrações
          links: [
            {
              path: '/ra',
              icon: 'mr-2 fas fa-tachometer-alt fa-fw',
              description: 'Dashboard', // Dashboard
            },
            {
              path: '/ra/all',
              icon: 'mr-2 fas fa-list-alt fa-fw',
              description: 'Reclamações', // All
            },
            {
              path: '/ra/report',
              icon: 'mr-2 fas fa-chart-bar fa-fw',
              description: 'Relatórios', // Report
            },
          ],
        },
      ],

      submenu: [
        {
          title: this.$t('generic-str.menu.integrations'), // Integrações
          links: [
            {
              path: '/integrations',
              icon: 'fas fa-id-badge fa-fw',
              description: this.$t('generic-str.menu.catalog'), // Catálogo
            },
            {
              path: '/integrations/installed',
              icon: 'fas fa-briefcase fa-fw',
              description: this.$tc('generic-str.menu.installed', 2), // Instalados
            },
            {
              path: '/webhooks',
              icon: 'briefcase',
              description: 'Webhooks',
            },
          ],
        },
        {
          title: this.$t('generic-str.menu.settings'), // Ajustes
          links: [
            {
              path: '/settings',
              icon: 'fas fa-id-badge fa-fw',
              description: this.$t('generic-str.menu.config'), // Configurações
            },
            // {
            //   path: '/settings/resources',
            //   icon: 'fas fa-headphones-alt fa-fw',
            //   description: 'Recursos',
            //   management: true,
            // },
            // {
            //   path: '/settings/messages',
            //   icon: 'fas fa-paper-plane fa-fw',
            //   description: 'Mensagens automáticas',
            //   management: true,
            // },
          ],
        },
      ],

      adminMenu: [
        {
          title: this.$t('generic-str.menu.general'), // Geral
          links: [
            {
              path: '/admin/customers',
              icon: 'address-card',
              description: this.$t('generic-str.menu.customers'), // Clientes
            },
            {
              path: '/admin/antifraud',
              icon: 'user-secret',
              description: this.$t('generic-str.menu.anti-fraud'), // Antifraude
            },
            {
              path: '/admin/providers',
              icon: 'archive',
              description: this.$tc('generic-str.menu.providers', 2), // Fornecedores
            },
            {
              path: '/admin/short-codes',
              icon: 'hashtag',
              description: 'Short Codes',
              role: ['admin'],
            },
            {
              path: '/admin/products',
              icon: 'box-open',
              description: this.$tc('generic-str.lbl-product', 2), // Produtos
              role: ['admin'],
            },
            {
              path: { name: 'AdminPlansIndex' },
              icon: 'shopping-cart',
              description: this.$tc('app.plan', 2), // Produtos
              role: ['admin'],
            },
            {
              path: '/admin/tickets',
              icon: 'question-circle',
              description: this.$t('generic-str.menu.calls'), // Chamados
            },
          ],
        },
        {
          title: 'SMS',
          links: [
            /*  {
              path: '/admin/sms/dashboard',
              icon: 'chart-bar',
              description: 'Dashboard',
            },  */
            {
              path: '/admin/sms/insights',
              icon: 'chart-bar',
              description: this.$tc('generic-str.report', 1), // Relatório
            },
            {
              path: '/admin/sms/history',
              icon: 'history',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
            {
              path: '/admin/sms/monitor',
              icon: 'desktop',
              description: this.$t('app.monitor'), // Monitor
            },
            /*  {
              path: '/admin/sms/sales',
              icon: 'chart-bar',
              description: 'Relatório de Venda',
            },  */
          ],
        },
        {
          title: 'E-Mail',
          links: [
            {
              path: '/admin/email/insights',
              icon: 'chart-bar',
              description: this.$tc('generic-str.report', 1), // Relatório
            },
            {
              path: '/admin/email/history',
              icon: 'history',
              description: this.$t('generic-str.menu.history'), // Histórico
            },
          ],
        },
        {
          title: 'Developer',
          links: [
            {
              path: '/developer/components',
              icon: 'code', // <i class="fab fa-dev"></i>
              description: 'Componentes',
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // this.checkLive();
    this.$live.setup(this);
    this.changeLiveVolume();

    this.updateMenu();
    this.checkClient();
    this.$root.$on('checkClient', this.checkClient);

    document.addEventListener('click', (e) => {
      if (
        !e.target.matches(
          '#sidebarMainMenu, #sidebarMainMenu *, #top-menu > button, #top-menu > button *, #parent-sidebar, #parent-sidebar *, [id^="sidebarSubMenu"], [id^="sidebarSubMenu"] *',
        )
      ) {
        this.reqCloseMenus(true);
      }
    });

    // setTimeout(() => {
    //   const el = this.$refs.sideBarEl.$el;

    //   el.onscroll = () => this.scrollConfig();

    //   window.onresize = () => this.scrollConfig();

    //   this.scrollConfig();
    // }, 100);

    let x = 0;
    let y = 0;

    this.$root.$on('bv::collapse::state', (id, show) => {
      const menu = document.getElementById('top-menu');

      if (id.indexOf('sidebarSubMenu') === 0) {
        this.subMenuOpened =
          show ||
          document.querySelector('[id^="sidebarSubMenu"].show') !== null;
      } else if (menu && id === 'sidebarMainMenu') {
        const mn = menu.nextElementSibling;
        const main = document.scrollingElement;

        if (show) {
          x = window.pageXOffset;
          y = window.pageYOffset;

          mn.style.left = `${-x}px`;
          mn.style.top = `${-y}px`;
        }

        main.classList.toggle('disable-touch', show);

        if (!show) {
          this.reqCloseMenus(false);

          mn.style.left = null;
          mn.style.top = null;

          main.scrollTo(x, y);
        }
      }
    });

    this.$root.$on('live:notify', (enable) => {
      // console.log('emitLiveNotification', enable);

      if (enable) {
        this.emitLiveNotification();
      } else {
        this.hasNotification = false;
      }
    });

    // this.$root.$on('emitSound', () => {
    //   console.log('{this.$root.$on}');
    //   this.emitLiveNotification();
    //   console.log('--------');
    // });

    // document.addEventList('click', () => {
    //   setTimeout(() => this.reqCloseMenus(), 1);
    // });

    // document.addEventList('touchstart', () => {
    //   setTimeout(() => this.reqCloseMenus(), 1);
    // });
  },
  watch: {
    $route() {
      this.updateMenu();
    },
  },
  methods: {
    permission(route) {
      return Permissions.isRole(this.$store.state.account.role, route);
    },
    groupPermission(id) {
      if (id === 'live' && !this.$store.state.plans.omniPlan) return false;
      // if (id === 'whatsappjava') return this.account.whitelabel_id === null;
      return Permissions.isRoleGroup(this.$store.state.account.role, id);
    },
    isRole(role) {
      return role.split(',').indexOf(this.$store.state.auth.user.role) !== -1;
    },
    checkClient() {
      // console.log('White label id');
      // console.log(this.account.whitelabel_id);
      // if ((this.$store.state.account.id !== '142a07bd-25d6-4681-989d-d0cb0b4728ce' && this.$store.state.account.id !== 'cb83acd8-9746-4aeb-bda5-5d6bdffae4c3') && this.account.whitelabel_id !== null) {
      //   this.mainMenu.splice(8, 1);
      // }

      // if ((this.$store.state.account.id === '142a07bd-25d6-4681-989d-d0cb0b4728ce' || this.$store.state.account.id === 'cb83acd8-9746-4aeb-bda5-5d6bdffae4c3')) {
      //   this.mainMenu.splice(2, 1);
      // }

      Promise.all([RCSService.getAgent(), WhatsAppService.getClient()])
        .then(
          (responses) => {
            this.client = responses[0].data;
            const posWhats = 2;
            const posRCS = 4;
            if (this.account.whitelabel_id == null) {
              // posWhats = 3;
              // posRCS = 4;
            }
            this.mainMenu[posRCS].subMenu[0].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu[1].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu[2].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu[3].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu[4].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu[5].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu[6].disabled = !this.client.active;
            this.mainMenu[posRCS].subMenu.splice(7, 1);

            if (!this.client.active) {
              this.mainMenu[posRCS].subMenu.push({
                path: '/rcs/request',
                icon: 'fas fa-unlock',
                description: 'Solicitar Acesso',
              });
            }

            this.clientWhats = responses[1].data;
            this.mainMenu[posWhats].subMenu[0].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[1].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[2].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[3].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[4].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[5].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[6].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu[7].disabled =
              !this.clientWhats.active;
            this.mainMenu[posWhats].subMenu.splice(8, 1);

            if (!this.clientWhats.active) {
              this.mainMenu[posWhats].subMenu.push({
                path: '/whatsapp/request',
                icon: 'fas fa-unlock',
                description: 'Solicitar Acesso',
              });
            }
            this.$forceUpdate();
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    forceEnableSoundNotification() {
      // console.log('this.$refs.soundNotification', this.$refs.soundNotification);

      if (
        !this.audioEnabled &&
        this.$live &&
        this.$refs.soundNotification &&
        this.$refs.soundNotification[0]
      ) {
        const sound = this.$refs.soundNotification[0];
        sound.muted = true;
        sound.play();
        sound.pause();
        sound.muted = false;
        this.audioEnabled = true;
        sound.volume = this.$live.volume;
      }
    },
    emitLiveNotification() {
      const path = this.$router.history.current.path;

      this.hasNotification = true; // path.indexOf('/live') !== 0;

      if (!this.timeoutSoundRunning && !this.isMuted) {
        clearTimeout(this.timeoutSound);

        this.timeoutSound = setTimeout(() => {
          // if (!this.isMuted) this.$refs.soundNotification.play();

          if (this.$refs.soundNotification && this.$refs.soundNotification[0]) {
            const played = this.$refs.soundNotification[0].play();

            if (!this.audioEnabled && played.catch && !this.audioIssue) {
              played
                .then(() => {
                  this.audioIssue = false;
                })
                .catch(() => {
                  this.audioIssue = true;

                  this.$toast.show({
                    title: this.$tc('generic-str.notification', 2),
                    content: this.$t('generic-str.sound-notification'),
                    type: 'info',
                    requireInteraction: (accepted) => {
                      if (accepted) this.forceEnableSoundNotification();
                    },
                  });
                });
            }
          }

          this.timeoutSoundRunning = true;

          // Permite nova notificações somente após 2 segundos
          setTimeout(() => {
            this.timeoutSoundRunning = false;
          }, 2000);
        }, 500);
      }
    },
    changeLiveVolume() {
      if (!this.$refs.soundNotification) return;

      let el = this.$refs.soundNotification;

      if (!el.volume) el = this.$refs.soundNotification[0];

      if (!el) return;

      el.volume = this.$live.volume;
    },
    toggleLiveVolume() {
      const menuVolume = this.$refs.menuVolume;

      menuVolume.classList.toggle('active');

      if (menuVolume.classList.contains('active')) {
        this.$refs.targetMenuVolume.style.top = `${
          menuVolume.getBoundingClientRect().top
        }px`;
      } else {
        this.$refs.targetMenuVolume.style.top = '-999px';
      }
    },
    // checkLive() {
    //   this.isLiveOnline = this.$live.online;
    //   this.hasNotification = this.$live.hasNotification;

    //   console.log('this.$live.hasNotification', this.$live.hasNotification);

    //   // console.log('this.hasNotification', this.hasNotification);
    //   // console.log('this.isLiveOnline', this.isLiveOnline);
    //   setTimeout(() => this.checkLive(), 1000);

    //   console.log('Sidebar.vue:checkLive');

    //   this.$live.setup(this);
    // },
    scrollConfig() {
      const el = this.$refs.sideBarEl.$el;

      this.scrollAtTop = el.scrollTop < 32;
      this.scrollAtBottom =
        el.scrollTop + el.clientHeight > el.scrollHeight - 32;
    },
    scrollTo(top) {
      const el =
        this.$refs.sideBarEl && this.$refs.sideBarEl.$el
          ? this.$refs.sideBarEl.$el
          : null;

      if (el) {
        const pos = top ? 0 : el.scrollHeight;

        el.scrollTo({
          top: pos,
          behavior: 'smooth',
        });
      }
    },
    updateMenu() {
      const path = this.$router.currentRoute.path;

      for (let i = this.mainMenu.length - 1; i >= 0; i -= 1) {
        const menus = this.mainMenu[i].subMenu;

        if (menus) {
          for (let j = menus.length - 1; j >= 0; j -= 1) {
            if (menus[j].path === path) {
              this.currentPath = this.mainMenu[i].id || this.mainMenu[i].path;
              return;
            }
          }
        }
      }

      this.currentPath = path;
    },
    reqCloseMenus(main) {
      if (this.closeMenusTimeout) clearTimeout(this.closeMenusTimeout);

      this.closeMenusTimeout = setTimeout(() => {
        this.closeMenus(main);
      }, 120);
    },
    closeMenus(main) {
      this.subMenuOpened = false;

      const subs = document.querySelectorAll('[id^="sidebarSubMenu"].show');

      if (subs.length) {
        for (let i = subs.length - 1; i >= 0; i -= 1) {
          this.$root.$emit('bv::toggle::collapse', subs[i].id);
        }
      }

      if (main && document.querySelector('#sidebarMainMenu.show')) {
        this.$root.$emit('bv::toggle::collapse', 'sidebarMainMenu');
      }

      document.scrollingElement.classList.toggle('disable-touch', false);
    },
    rebranding() {
      console.log('rebranding', this.$rebranding.active);
      this.$rebranding.toggle();
      window.location.reload();
    },
  },
  computed: {
    isDashboard() {
      return (
        this.menu === '' ||
        this.menu === 'customers' ||
        this.menu === 'users' ||
        this.menu === 'settings' ||
        this.menu === 'billing' ||
        this.menu === 'usage' ||
        this.menu === 'webhooks' ||
        this.menu === 'invoices' ||
        this.menu === 'invoice'
      );
    },
    account() {
      return this.$store.state.account;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .sidebar {
//   display: none;
// }

#soundNotification {
  pointer-events: none;
  visibility: hidden;
  height: 1px;
  width: 1px;
}

.sidebar-right {
  right: -225px;
}

#parent-sidebar {
  position: fixed;
  z-index: 1050;
  width: 100%;
  left: 0;
  top: 0;
}

// #sidebarBack {
//   background: transparent;
//   position: fixed;
//   z-index: 2001;
//   left: -101%;
//   top: 62px;
//   height: 100%;
//   width: 100%;
//   transition: .1s all ease-out;
//   overflow: hidden;
//   display: none;

//   &::after {
//     background: transparent;
//     box-shadow: 2em 2em 16em 20em rgba(0,0,0,.4);
//     height: 100%;
//     max-width: 1px;
//     content: "";
//     display: block;
//   }
// }

#sidebarMainMenu {
  display: block !important;
  position: fixed;
  // z-index: 1052;
  z-index: 999;
  overflow: auto;
  overflow-x: hidden;
  top: 62px;
  left: 0;
  border-right: 1px solid #d6dadd;
  background: #fff;
  width: 62px;
  // width: 262px;
  height: calc(100vh - 62px) !important;
  transition: left 0.2s ease-out;

  .side-bar-sub {
    position: relative;
    min-height: 670px;
    height: 100%;
  }

  .live-pushs {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 40px;
    width: 14px;
    height: 14px;
    background: #f00;
    border: 2px solid #fff;

    &.live-online {
      background: #00cc00;
    }

    &.live-notifications {
      // animation-play-state: running;
      animation: bounceAnimation 0.5s ease-out infinite alternate;
    }
  }

  // &.show + #sidebarBack {
  //   left: 0;
  // }

  .scroll-to-top,
  .scroll-to-bottom {
    overflow: hidden;
    position: fixed;
    z-index: 1053;
    width: 60px;
    border: none;
    top: 62px;
    left: 0;
    opacity: 0;
    height: 0;
    background: linear-gradient(
      0,
      rgba(255, 255, 255, 0) 0%,
      #fff 40%,
      #fff 100%
    ) !important;
    transition: 0.3s all ease-out;

    display: none;
  }

  .scroll-to-bottom {
    top: auto;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #fff 40%,
      #fff 100%
    ) !important;
  }
}

#sidebarMainMenu header,
#sidebarMainMenu footer {
  width: 100%;
}

#sidebarMainMenu footer {
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (max-height: 836px) {
  #sidebarMainMenu footer {
    margin-bottom: 82px;
    position: static;
  }

  #sidebarMainMenu {
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    &.show .scroll-to-top.scroll-visible,
    &.show .scroll-to-bottom.scroll-visible {
      opacity: 1 !important;
      height: 60px !important;
    }
  }
}

@media (max-height: 662px) and (min-width: 993px) {
  .scroll-to-top.scroll-visible,
  .scroll-to-bottom.scroll-visible {
    opacity: 1 !important;
    height: 60px !important;
  }
}

// menu-volume

#sidebarMainMenu header a,
#sidebarMainMenu header button,
#sidebarMainMenu footer a,
#sidebarMainMenu footer .menu-config {
  position: relative;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  // background: #fc0 !important;
  margin: 0 auto !important;
  display: block !important;
  height: 60px !important;
  width: 60px !important;
  padding: 15px !important;
  height: 48px !important;
  width: 48px !important;
  padding: 10px !important;
  color: #d6dadd !important;
  text-align: center !important;
  transition: color 0.3s ease !important;
}

#sidebarMainMenu a:hover,
#sidebarMainMenu button:hover,
#sidebarMainMenu a.active,
#sidebarMainMenu button.active,
#sidebarMainMenu [aria-expanded='true'],
#sidebarMainMenu .menu-config:hover,
#sidebarMainMenu .menu-config.active,
#sidebarMainMenu .menu-config[aria-expanded='true'] {
  color: --clr-yup-purple !important;
}

#sidebarMainMenu header a i,
#sidebarMainMenu header button i,
#sidebarMainMenu footer a i,
#sidebarMainMenu footer .menu-config i,
.target-icon i {
  height: 30px;
  width: 30px;
  font-size: 15pt;
  height: 30px;
  width: 30px;
  font-size: 12pt;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

[data-target='menu-volume'] {
  position: fixed;
  z-index: 2000;
  top: -999px;
  left: 5px;
  padding: 13px 10px !important;
  padding: 9px !important;
  width: 50px;
  overflow: hidden;
  border-radius: 0.6em;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  box-shadow: none;

  .menu-volume-sub {
    width: 190px !important;
  }

  // &.active {
  // }

  pointer-events: auto;
  border: thin solid #ccc !important;
  background: #fff !important;
  width: 220px !important;
  opacity: 1 !important;

  .target-icon {
    float: left;
  }

  .target-input {
    float: right;
    width: 140px;
    margin-top: 6px;

    & ~ .target-icon .fa-volume-mute {
      display: none;
    }
  }

  .target-input[data-volume='0'] ~ .target-icon {
    .fa-volume-up {
      display: none;
    }

    .fa-volume-mute {
      display: flex;
    }
  }
}

[id^='sidebarSubMenu'] {
  // box-shadow: 0 2px 4px rgba(0, 0, 0, .8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), 0 32px 25px 9px rgba(0, 0, 0, 0.4);
  background-color: #0e1421 !important;
  width: 256px;
  position: fixed;
  z-index: 998;
  left: 62px;
  top: 62px;
  font-weight: 700;
  height: calc(100vh - 62px) !important;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  .badge {
    float: right;
    border-radius: 0.2em;

    &:not(:last-child) {
      margin-left: 3px;
    }
  }

  & > aside:last-child {
    padding-bottom: 82px;
  }
}

[id^='sidebarSubMenu'] h3 {
  color: #fff;
  font-size: 12pt;
  font-weight: bold;
  padding: 1.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

[id^='sidebarSubMenu'] h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12pt;
  padding: 1.5em;
  padding-bottom: 0.5em;
  margin: 0;
  color: #a88eb2;
}

[id^='sidebarSubMenu'] .list-group-item {
  padding: 1em 1.5em;
  background: rgba(255, 255, 255, 0);
  border: none;
  color: #fff;
  font-weight: 600;
}

[id^='sidebarSubMenu'] .list-group-item i {
  margin-right: 0.4em;
  color: inherit;
}
[id^='sidebarSubMenu'] .list-group-item.list-disabled {
  color: #a98fb3;
}

[id^='sidebarSubMenu'] .list-group-item:not(.list-disabled):hover,
[id^='sidebarSubMenu'] .list-group-item:not(.list-disabled):active {
  background: rgba(255, 255, 255, 0.1);
  border: none;
}

#parent-sidebar .toggle-btn {
  float: left;
  border: none;
  background: transparent url(../../assets/btn-menu.svg) center center no-repeat;
  color: transparent;
  border-right: 1px solid #d6dadd;
  width: 62px;
  height: 62px;
}

#parent-sidebar .logo {
  // background: url(../../assets/logo.svg) no-repeat;
  background-size: contain;
  width: 168px;
  height: 30px;
  float: left;
  margin-top: 0.7em;
  margin-left: 1.5em;
}

#parent-sidebar .row {
  margin-top: 0.35em;
  margin-right: 1.5em;
  max-width: 92px;
  float: right;
}

#parent-sidebar .dropdown > button {
  position: relative;
  height: 2.5em;
  width: 2.5em;
  color: #0f0f0f;
}

#parent-sidebar .profile > button {
  color: #d6dadd;
}

#parent-sidebar .profile > button .status {
  border: 0.2em solid #fff;
  background: #fc0;
  width: 0.9em;
  height: 0.9em;
  position: absolute;
  bottom: -0.2em;
  left: -0.2em;
  content: '';
  border-radius: 100%;
}

#parent-sidebar .profile .fa-circle {
  font-size: 9pt;
  margin-right: 10px;
}

#parent-sidebar .notifications > button {
  background: transparent;
  color: #d6dadd;
  border: none;
}

/*#parent-sidebar .notifications button:hover {
  color: #752de6 !important;
}*/

#parent-sidebar .notification-content {
  width: 285px;
  padding: 0;
}

#parent-sidebar .notification-content h4 {
  font-weight: bold;
  font-size: 110%;
  padding: 0.9em;
  background: #fff;
  margin: 0;
  border-bottom: 1px solid #d6dadd;
}

#parent-sidebar .notification-content aside {
  border: none !important;
  border-bottom: 1px solid #fcfcfc;
  max-height: 320px;
  overflow: auto;
}

#sidebarMainMenu.show {
  left: 0 !important;
}

[id^='sidebarSubMenu'] {
  transition: left 0.2s ease-out !important;
  left: -256px !important;
}

[id^='sidebarSubMenu'].show {
  left: 62px !important;
}

@mixin side-bar-mobile {
  #sidebarMainMenu {
    left: -64px !important;
  }

  // #sidebarBack {
  //   display: block !important;
  // }

  // #sidebarMainMenu footer .menu-config {
  //   display: none !important;
  // }

  // [id^="sidebarSubMenu"].show {
  //   left: -256px !important;
  // }

  // #sidebarMainMenu.show ~ [id^="sidebarSubMenu"] {
  //   left: 62px !important;
  //   display: block !important;
  // }
}

@media (max-width: 992px) {
  @include side-bar-mobile;
}

@import '@/assets/scss/_variables';

.site-logo li .logo {
  top: 15px;
  img {
    max-width: none;
  }
}

body .aside-toolbar {
  background: $primary;
}
.sidebar.sidebar-left .sidebar-content.mini {
  width: 65px;
}
.sidebar.sidebar-left .sidebar-content .main-menu {
  display: flex;
  height: 100%;
  position: absolute;
  padding: 0;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu {
  width: 190px;
  box-shadow: 3px 0 20px -3px rgba(0, 0, 0, 0.14);
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu.dock {
  width: 65px;
  background: #fafafa; /* #f0f6ff */
  padding: 0;
  box-shadow: none;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu.dock
  > li
  > a {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
  .badge {
    padding: 0px 2px;
  }
}
.active {
  /*background-image: linear-gradient(90deg,rgba(21,9,26,0) 67%,rgba(255,255,255,.1))*/
  background: #fff;
}
.tooltip[x-placement^='right'] {
  margin-left: 5px !important;
  margin-right: -200px !important;
}
.li-s-menu-action {
  .badge {
    display: inline-block !important;
    position: initial !important;
    margin-left: 10px;
  }
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.ra-button span {
  display: block;
  height: 30px;
  background: url(/assets/img/icons/ra.svg);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  width: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.ra-button span:hover {
  background: url(/assets/img/icons/ra_active.svg);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  width: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.menu-config[aria-expanded='true'].ra-button span {
  background: url(/assets/img/icons/ra_active.svg);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  width: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.fa-java-whats {
  background: #ccc;
  color: #752de6 !important;
}
</style>
